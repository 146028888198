import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { createColumns } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/AssetRolesTable/AssetRolesTable.columns";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { t } from "@/translations";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

export const AssetRolesTable = React.memo(function AssetRolesTable({
    assetRoles,
    selectedAssetRoleIds,
    selectionChange,
    onAssetRoleClick,
    selectionContextMenu,
    // hasRights,
}) {
    const { messageService } = useDic();
    const rowSelection = useSyncRowSelection(
        selectedAssetRoleIds,
        selectionChange,
        [assetRoles],
        record => isEmpty(record?.assets),
    );

    // const rowHighlights = useMemo(
    //     () =>
    //         ({ id }) =>
    //             hasRights && hasRights(id) ? "green" : false,
    //     [hasRights],
    // );

    const onClick = useCallback(
        record => {
            if (isEmpty(record.assets)) {
                return messageService.warn({
                    content: t(
                        "asset-roles-table.message.warn.no-asset-in-asset-role",
                        { roleName: record.name },
                    ),
                });
            }
            onAssetRoleClick(record);
        },
        [onAssetRoleClick, messageService],
    );
    const columns = createColumns(onClick);

    return (
        assetRoles && (
            <TableLocalFiltered
                dataSource={assetRoles}
                columns={columns}
                rowSelection={rowSelection}
                rowKey="id"
                selectionContextMenu={selectionContextMenu}
                // rowHighlights={rowHighlights}
            />
        )
    );
});

AssetRolesTable.propTypes = {
    assetRoles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }),
    ).isRequired,
    selectedAssetRoleIds: PropTypes.array.isRequired,
    selectionChange: PropTypes.func.isRequired,
    onAssetRoleClick: PropTypes.func.isRequired,
    hasRights: PropTypes.func,
};
