import { useRoute } from "@/modules/router/index";
import { useContext } from "react";
import { getResource, RouteDataContext } from "../RouteDataContextProvider";

export const getFirstUserWithEmail = loadable => {
    const firstUser = loadable.valueMaybe()?.content?.[0];

    return `${firstUser?.fullName} (${firstUser?.email})`;
};
export const getUserNames = loadable =>
    loadable
        .valueMaybe()
        ?.content.map(user => user.fullName)
        .join(", ");

export const getNumberOfUsers = loadable =>
    loadable.valueMaybe()?.content?.length;
export const isMultipleUsers = loadable => getNumberOfUsers(loadable) > 1;

export const useRouteAdminUsersList = () => {
    const { route } = useRoute();
    const context = useContext(RouteDataContext);

    return {
        adminUsersListLoadable: getResource(
            "users",
            "userIds",
            route.params?.userIds,
            context.resources,
        ),
    };
};
