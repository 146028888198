import { useDic } from "@/components/Dic/useDic.hook";
import {
    LocationMenuContainer,
    LocationMenuItem,
    LocationSubMenu,
} from "@/components/Menu";
import { SidebarContent } from "@/components/Sidebar";
import { useFeatureFlag } from "@/security/hooks/useFeatureFlag.hook";
import React from "react";

export const AdministrationAppMenu = () => {
    const {
        administrationAppLocations: {
            activityLogLocation,
            adminReportsLocation,
            assetRightsLocation,
            atlassianGroupsLocation,
            businessRolesLocation,
            constantsLocation,
            featureFlagsLocation,
            globalAlertsLocation,
            globalAlertsRulesLocation,
            groupsLocation,
            imInstancesLimitLocation,
            integrationManagerVersionsLocation,
            loggersLocation,
            logsLocation,
            partitionUpgradesLocation,
            pmFeatureFlagsLocation,
            releaseVersionsLocation,
            rightsLocation,
            ssoAdminLocation,
            usersLocation,
            partitionLimitLocation,
            engSupportUsersLocation,
            enableDeeplinksLocation,
            permissionsAdminLocation,
            permissionsAdminUsersLocation,
            permissionsAdminUserGroupsLocation,
            permissionsAdminAssetGroupsLocation,
            permissionsAdminBusinessRolesLocation,
            permissionsAdminAtlassianGroupsLocation,
            IaaSLocation,
        },
    } = useDic();
    const isNewPermissionsEnabled = useFeatureFlag(
        "new-admin-asset-permissions",
    );
    return (
        <SidebarContent>
            <LocationMenuContainer mode="inline" condensed eager>
                <LocationSubMenu
                    key={rightsLocation.routeName}
                    $location={rightsLocation}
                >
                    <LocationMenuItem
                        key={usersLocation.routeName}
                        $location={usersLocation}
                    />
                    <LocationMenuItem
                        key={groupsLocation.routeName}
                        $location={groupsLocation}
                    />
                    <LocationMenuItem
                        key={assetRightsLocation.routeName}
                        $location={assetRightsLocation}
                    />
                    <LocationMenuItem
                        key={businessRolesLocation.routeName}
                        $location={businessRolesLocation}
                    />
                    <LocationMenuItem
                        key={atlassianGroupsLocation.routeName}
                        $location={atlassianGroupsLocation}
                    />
                </LocationSubMenu>
                {!isNewPermissionsEnabled ? null : (
                    <LocationSubMenu
                        key={permissionsAdminLocation.routeName}
                        $location={permissionsAdminLocation}
                    >
                        <LocationMenuItem
                            key={permissionsAdminUsersLocation.routeName}
                            $location={permissionsAdminUsersLocation}
                        />
                        <LocationMenuItem
                            key={permissionsAdminUserGroupsLocation.routeName}
                            $location={permissionsAdminUserGroupsLocation}
                        />
                        <LocationMenuItem
                            key={permissionsAdminAssetGroupsLocation.routeName}
                            $location={permissionsAdminAssetGroupsLocation}
                        />
                        <LocationMenuItem
                            key={
                                permissionsAdminBusinessRolesLocation.routeName
                            }
                            $location={permissionsAdminBusinessRolesLocation}
                        />
                        <LocationMenuItem
                            key={
                                permissionsAdminAtlassianGroupsLocation.routeName
                            }
                            $location={permissionsAdminAtlassianGroupsLocation}
                        />
                    </LocationSubMenu>
                )}
                <LocationMenuItem
                    key={ssoAdminLocation.routeName}
                    $location={ssoAdminLocation}
                />
                <LocationSubMenu
                    key={logsLocation.routeName}
                    $location={logsLocation}
                >
                    <LocationMenuItem
                        key={activityLogLocation.routeName}
                        $location={activityLogLocation}
                    />
                </LocationSubMenu>

                <LocationMenuItem
                    key={loggersLocation.routeName}
                    $location={loggersLocation}
                />
                <LocationSubMenu
                    key={globalAlertsLocation.routeName}
                    $location={globalAlertsLocation}
                >
                    <LocationMenuItem
                        key={globalAlertsRulesLocation.routeName}
                        $location={globalAlertsRulesLocation}
                    />
                </LocationSubMenu>
                <LocationSubMenu
                    key={constantsLocation.routeName}
                    $location={constantsLocation}
                >
                    <LocationMenuItem
                        key={releaseVersionsLocation.routeName}
                        $location={releaseVersionsLocation}
                    />
                    <LocationMenuItem
                        key={partitionUpgradesLocation.routeName}
                        $location={partitionUpgradesLocation}
                    />
                    <LocationMenuItem
                        key={imInstancesLimitLocation.routeName}
                        $location={imInstancesLimitLocation}
                    />
                    <LocationMenuItem
                        key={partitionLimitLocation.routeName}
                        $location={partitionLimitLocation}
                    />
                    <LocationMenuItem
                        key={engSupportUsersLocation.routeName}
                        $location={engSupportUsersLocation}
                    />
                    <LocationMenuItem
                        key={enableDeeplinksLocation.routeName}
                        $location={enableDeeplinksLocation}
                    />
                </LocationSubMenu>
                <LocationMenuItem
                    key={integrationManagerVersionsLocation.routeName}
                    $location={integrationManagerVersionsLocation}
                />
                <LocationMenuItem
                    key={featureFlagsLocation.routeName}
                    $location={featureFlagsLocation}
                />
                <LocationMenuItem
                    key={adminReportsLocation.routeName}
                    $location={adminReportsLocation}
                />
                <LocationMenuItem
                    key={pmFeatureFlagsLocation.routeName}
                    $location={pmFeatureFlagsLocation}
                />
                <LocationMenuItem
                    key={IaaSLocation.routeName}
                    $location={IaaSLocation}
                />
            </LocationMenuContainer>
        </SidebarContent>
    );
};
