import { useDic } from "@/components/Dic/useDic.hook";
import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import { useAssetsSelection } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/hooks/useAssetsSelection.hook";
import { useGroupedPermissionsResource } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { PermissionsDrawerContainer } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import {
    ADMIN_KIND,
    ASSET_KIND,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { useAdminBusinessRolesResource } from "@/components/Permissions/BusinessRolesPage/loadables";
import { AssetGroupsTable } from "@/components/Permissions/UsersAssets/assets/AssetGroupsTable.component";
import { DataUploadsTable } from "@/components/Permissions/UsersAssets/assets/DataUploadsTable.component";
import { IntegrationsTableContainer } from "@/components/Permissions/UsersAssets/assets/IntegrationsTable.container";
import { PartitionsTableContainer } from "@/components/Permissions/UsersAssets/assets/PartitionsTable.container";
import { SchedulersTable } from "@/components/Permissions/UsersAssets/assets/SchedulersTable.component";
import { WorkflowTable } from "@/components/Permissions/UsersAssets/assets/WorkflowTable.component";
import { createProjectIdSubsetFilter } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { joinUserIdsToUrlParam } from "@/components/Permissions/utils";
import { assetType } from "@/constants/assets.constants";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { size } from "lodash/fp";
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

export const TABS = {
    PARTITIONS: assetType.partition,
    INTEGRATIONS: assetType.integration,
    DATA_UPLOADS: assetType.dataUpload,
    WORKFLOWS: assetType.workflow,
    SCHEDULERS: assetType.scheduler,
    ASSET_GROUPS: assetType.assetGroup,
};

const assetKind = ASSET_KIND.ASSET;

export const VIEWS = {
    DEFAULT: null,
    PERMISSIONS: "PERMISSIONS",
};

export const getName = tab => {
    switch (tab) {
        case TABS.PARTITIONS:
            return t("global.partitions");
        case TABS.INTEGRATIONS:
            return t("global.integrations");
        case TABS.DATA_UPLOADS:
            return t("global.data-uploads");
        case TABS.WORKFLOWS:
            return t("global.workflows");
        case TABS.SCHEDULERS:
            return t("global.schedulers");
        case TABS.ASSET_GROUPS:
            return t("global.asset-groups");
        default:
            return "";
    }
};

const getDescription = (selectedItems, tab) =>
    size(selectedItems) > 1
        ? `${size(selectedItems)} selected ${getName(tab).toLowerCase()}`
        : `${size(selectedItems)} selected ${getName(tab).toLowerCase()}`;

export const UsersAssetsComponent = React.memo(
    forwardRef(
        (
            {
                isAdmin,
                accountId,
                entityKind,
                selectedEntities,
                rightsResource,
                workflowRightsResource,
                saveRightsResource,
                // onClose,
                // visible = true,
                // displaySuperAssets = false,
            },
            ref,
        ) => {
            const {
                locationRouterService,
                accountAppLocations: { permissionsAccountSubAssetsLocation },
                administrationAppLocations: {
                    permissionsAdminSubAssetsLocation,
                },
            } = useDic();
            const [tab, setTab] = useState(TABS.PARTITIONS);
            const [view, setView] = useState(VIEWS.DEFAULT);
            const showPermissions = useCallback(
                () => setView(VIEWS.PERMISSIONS),
                [setView],
            );

            const currentChildRef = useRef();

            useImperativeHandle(ref, () => ({
                hidePermissionsDrawer() {
                    setView(VIEWS.DEFAULT);
                },
                reload: () => currentChildRef.current.reload(),
            }));

            const {
                selectedAssets,
                onAssetsSelection,
                onAssetClick,
                noAssetsSelected,
                resetAssets,
            } = useAssetsSelection({ afterClick: showPermissions });

            const onPermissionsDrawerClose = useCallback(
                () => setView(VIEWS.DEFAULT),
                [setView],
            );
            useEffect(() => {
                resetAssets();
            }, [tab, resetAssets]);
            const onCloseAll = useCallback(() => {
                setView(VIEWS.DEFAULT);
                // onClose();
            }, []);
            // useEffect(() => () => setView(VIEWS.DEFAULT), [visible]);

            const groupedPermissionsResource = useGroupedPermissionsResource();
            const businessRolesResource = useAdminBusinessRolesResource();
            // const assetRolesResource = useAdminAssetRolesResource();
            const loadables = useComposeLoadablesMemoized([
                groupedPermissionsResource.loadable,
                businessRolesResource.loadable,
                // assetRolesResource.loadable,
                rightsResource.loadable,
                workflowRightsResource.loadable,
            ]);

            const selectionContextMenu = useMemo(
                () => [
                    {
                        label: t("general.set-permissions"),
                        onClick: showPermissions,
                    },
                ],
                [showPermissions],
            );

            logger.debug({
                logGroupKey: ["PERMISSIONS", "UsersAssetsAdminComponent"],
                color: "deepskyblue",
                data: {
                    selectedAssets,
                },
            });

            const tableProps = {
                subsetFilter: createProjectIdSubsetFilter({
                    accountId,
                }),
            };

            const onSubAssetClick = useCallback(
                (assetType, record) => {
                    const location = isAdmin
                        ? permissionsAdminSubAssetsLocation
                        : permissionsAccountSubAssetsLocation;
                    locationRouterService.navigate(location, {
                        userIds: joinUserIdsToUrlParam(selectedEntities),
                        assetType: assetType,
                        assetId: record.id,
                    });
                },
                [
                    isAdmin,
                    locationRouterService,
                    permissionsAccountSubAssetsLocation,
                    permissionsAdminSubAssetsLocation,
                    selectedEntities,
                ],
            );

            return (
                <>
                    <HeaderTabs
                        defaultActiveKey={TABS.PARTITIONS}
                        destroyInactiveTabPane
                        onChange={setTab}
                    >
                        <HeaderTabs.TabPane
                            tab={t("global.partitions")}
                            key={TABS.PARTITIONS}
                        >
                            <PartitionsTableContainer
                                ref={currentChildRef}
                                accountId={accountId}
                                userIds={selectedEntities}
                                onAssetClick={onAssetClick}
                                onSubAssetClick={onSubAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("global.integrations")}
                            key={TABS.INTEGRATIONS}
                        >
                            <IntegrationsTableContainer
                                ref={currentChildRef}
                                accountId={accountId}
                                userIds={selectedEntities}
                                onAssetClick={onAssetClick}
                                onSubAssetClick={onSubAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("global.data-uploads")}
                            key={TABS.DATA_UPLOADS}
                        >
                            <DataUploadsTable
                                ref={currentChildRef}
                                accountId={accountId}
                                userIds={selectedEntities}
                                onAssetClick={onAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("global.workflows")}
                            key={TABS.WORKFLOWS}
                        >
                            <WorkflowTable
                                ref={currentChildRef}
                                accountId={accountId}
                                userIds={selectedEntities}
                                onAssetClick={onAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("global.schedulers")}
                            key={TABS.SCHEDULERS}
                        >
                            <SchedulersTable
                                ref={currentChildRef}
                                accountId={accountId}
                                userIds={selectedEntities}
                                onAssetClick={onAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                        <HeaderTabs.TabPane
                            tab={t("global.asset-groups")}
                            key={TABS.ASSET_GROUPS}
                        >
                            <AssetGroupsTable
                                ref={currentChildRef}
                                accountId={accountId}
                                userIds={selectedEntities}
                                onAssetClick={onAssetClick}
                                selectionChange={onAssetsSelection}
                                selectionContextMenu={selectionContextMenu}
                                selectedAssets={selectedAssets}
                                tableProps={tableProps}
                            />
                        </HeaderTabs.TabPane>
                    </HeaderTabs>
                    <LoadableRenderer
                        loadable={loadables}
                        loading={() => null}
                        hasValue={([
                            groupedPermissions,
                            businessRoles,
                            // assetRoles,
                            rights,
                            workflowRights,
                            // saveRights, // TODO
                        ]) => (
                            <>
                                <PermissionsDrawerContainer
                                    title="Asset Permissions"
                                    description={getDescription(
                                        selectedAssets[tab],
                                        tab,
                                    )}
                                    selectedAssets={selectedAssets}
                                    selectedEntities={selectedEntities}
                                    adminKind={ADMIN_KIND.GENERAL}
                                    entityKind={entityKind}
                                    assetKind={assetKind}
                                    rights={rights}
                                    workflowRights={workflowRights}
                                    groupedPermissions={groupedPermissions}
                                    onCancel={onCloseAll}
                                    onClose={onPermissionsDrawerClose}
                                    onSave={saveRightsResource.mutate}
                                    visible={Boolean(
                                        view === VIEWS.PERMISSIONS,
                                    )}
                                    businessRoles={businessRoles}
                                />
                            </>
                        )}
                    />
                </>
            );
        },
    ),
);
