import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { HeaderTabs } from "@/components/PageLayout/components/HeaderTabs.component";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageDescription } from "@/components/PageLayout/useSetPageDescription.hook";
import { AssetRolesTable } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/AssetRolesTable/AssetRolesTable.component";
import { useAssetRolesSelection } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/hooks/useAssetRolesSelection.hook";
import { useSuperAssetsSelection } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/hooks/useSuperAssetsSelection.hook";
import {
    useAdminUserRightsResource,
    useAdminUserWorkflowRightsResource,
    useGroupedPermissionsResource,
    useSaveAdminRights,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { PermissionsDrawerContainer } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import {
    ADMIN_KIND,
    ASSET_KIND,
    assetMatch,
    ENTITY_KIND,
    superAssetMatch,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { SuperAssetsTable } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/SuperAssetsTable/SuperAssetsTable.component";
import { useAdminAssetRolesResource } from "@/components/Permissions/AssetRolesPage/loadables";
import { useAdminBusinessRolesResource } from "@/components/Permissions/BusinessRolesPage/loadables";
import { AccountsTable } from "@/components/Permissions/UsersAssets/assets/AccountsTable.component";
import { VIEWS } from "@/components/Permissions/UsersAssets/UsersAssets.component";
import { useRouteUserIds } from "@/components/Permissions/utils";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    getNumberOfUsers,
    getUserNames,
    isMultipleUsers,
    useRouteAdminUsersList,
} from "@/mixpanel/hooks/useRouteAdminUsersList.hook";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { size } from "lodash/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";

const preferencesType = preferencesTypes.PERMISSIONS_ADMIN_ACCOUNT_LIST;

export const TABS = {
    ASSETS: ASSET_KIND.ASSET,
    ASSET_ROLES: ASSET_KIND.ASSET_ROLE,
    SUPER_ASSETS: ASSET_KIND.SUPER_ASSET,
};

export const getPageTitle = adminUsersListLoadable =>
    isMultipleUsers(adminUsersListLoadable)
        ? `Permission Management (${getNumberOfUsers(
              adminUsersListLoadable,
          )} selected users)`
        : `${getUserNames(adminUsersListLoadable)} Permission Management`;

const getDescription = (selectedItems, tab) =>
    size(selectedItems) > 1
        ? `${size(selectedItems)} selected ${getName(tab)}`
        : `${size(selectedItems)} selected ${getName(tab)}`;

export const getName = tab => {
    switch (tab) {
        case TABS.ASSETS:
            return t("global.accounts");
        case TABS.ASSET_ROLES:
            return t("global.asset-groups");
        case TABS.SUPER_ASSETS:
            return t("global.super-assets");
        default:
            return null;
    }
};

export const UsersAccountsAdminPage = () => {
    const { adminUsersListLoadable } = useRouteAdminUsersList();
    const {
        locationRouterService,
        administrationAppLocations: {
            permissionsAdminUsersAssetsLocation,
            permissionsAdminUsersLocation,
        },
    } = useDic();
    useFullWidthTableLayout();
    const userIds = useRouteUserIds();
    // useSetPageTitle(getPageTitle(adminUsersListLoadable));
    useSetPageDescription(
        isMultipleUsers(adminUsersListLoadable)
            ? `${getNumberOfUsers(adminUsersListLoadable)} selected users`
            : "",
    );
    const rightsResource = useAdminUserRightsResource({
        canFetch: !!userIds.length,
        userIds,
    });

    const workflowRightsResource = useAdminUserWorkflowRightsResource({
        canFetch: !!userIds.length,
        userIds,
    });

    const navigateToUsersList = useCallback(
        () => locationRouterService.navigate(permissionsAdminUsersLocation),
        [locationRouterService, permissionsAdminUsersLocation],
    );
    useSetBackButtonAction(navigateToUsersList);

    const [tab, setTab] = useState(TABS.ASSETS);
    const [view, setView] = useState(VIEWS.DEFAULT);

    const showPermissions = useCallback(
        () => setView(VIEWS.PERMISSIONS),
        [setView],
    );

    const {
        selectedSuperAssets,
        onSuperAssetsSelection,
        onSuperAssetClick,
        noSuperAssetsSelected,
        resetSuperAssets,
    } = useSuperAssetsSelection(showPermissions);
    const {
        selectedAssetRoles,
        selectedAssetRoleIds,
        onAssetRolesSelection,
        onAssetRoleClick,
        noAssetRolesSelected,
        resetAssetRoles,
    } = useAssetRolesSelection(showPermissions);
    const onPermissionsDrawerClose = useCallback(
        () => setView(VIEWS.DEFAULT),
        [setView],
    );
    useEffect(() => {
        // resetAssets();
        resetAssetRoles();
        resetSuperAssets();
    }, [resetSuperAssets, resetAssetRoles]);
    // const onCloseAll = useCallback(() => {
    //     setView(VIEWS.DEFAULT);
    //     // onClose();
    // }, [onClose]);
    // useEffect(() => () => setView(VIEWS.DEFAULT), [visible]);

    const groupedPermissionsResource = useGroupedPermissionsResource();
    const businessRolesResource = useAdminBusinessRolesResource();
    const assetRolesResource = useAdminAssetRolesResource();

    const loadables = useComposeLoadablesMemoized([
        groupedPermissionsResource.loadable,
        businessRolesResource.loadable,
        assetRolesResource.loadable,
        rightsResource.loadable,
        workflowRightsResource.loadable,
    ]);

    const afterSave = useCallback(() => {
        rightsResource.reload();
        onPermissionsDrawerClose();
    }, [onPermissionsDrawerClose, rightsResource]);

    const saveRightsResource = useSaveAdminRights({
        afterSave: afterSave,
    });

    const [selectedAccounts, setSelectedAccounts] = useState([]);

    const selectionContextMenu = useMemo(
        () =>
            [
                {
                    label: t("general.set-permissions"),
                    onClick: showPermissions,
                },
            ][showPermissions],
    );

    const actionMenu = useCallback(
        record => (
            <ActionButton
                wrapperProps={{ onClick: e => e.stopPropagation() }}
                record={record}
                items={[
                    {
                        title: t("permissions-table.account"),
                        onClick: record => {
                            setSelectedAccounts([record.id]);
                            showPermissions();
                        },
                    },
                    {
                        title: t("permissions-table.asset-permissions"),

                        onClick: () =>
                            locationRouterService.navigate(
                                permissionsAdminUsersAssetsLocation,
                                {
                                    accountId: record.id,
                                },
                            ),
                    },
                ]}
            />
        ),
        [
            locationRouterService,
            permissionsAdminUsersAssetsLocation,
            showPermissions,
        ],
    );

    logger.debug({
        logGroupKey: ["PERMISSIONS", "UserAccountsAdminPage"],
        color: "deepskyblue",
        data: {
            selectedAccounts,
            selectedSuperAssets,
            selectedAssetRoles,
        },
    });

    return (
        <>
            <LoadableRenderer
                loadable={loadables}
                hasValue={([
                    groupedPermissions,
                    businessRoles,
                    assetRoles,
                    rights,
                    workflowRights,
                    saveRights, // TODO
                ]) => (
                    <>
                        <HeaderTabs
                            defaultActiveKey={TABS.ASSETS}
                            onChange={setTab}
                        >
                            <HeaderTabs.TabPane
                                tab={t("global.accounts")}
                                key={TABS.ASSETS}
                            >
                                <AccountsTable
                                    userIds={userIds}
                                    preferencesType={preferencesType}
                                    actionMenu={actionMenu}
                                    selectionContextMenu={selectionContextMenu}
                                    selectionChange={selection =>
                                        setSelectedAccounts(selection)
                                    }
                                    selectedAssets={{
                                        project: selectedAccounts,
                                    }}
                                    hasAssetRights={(assetId, assetType) =>
                                        rights?.some(
                                            assetMatch({
                                                assetId,
                                                assetType,
                                            }),
                                        )
                                    }
                                />
                            </HeaderTabs.TabPane>

                            <HeaderTabs.TabPane
                                tab={t("global.asset-groups")}
                                key={TABS.ASSET_ROLES}
                            >
                                <AssetRolesTable
                                    assetRoles={assetRoles}
                                    onAssetRoleClick={onAssetRoleClick}
                                    selectionChange={onAssetRolesSelection}
                                    selectionContextMenu={selectionContextMenu}
                                    selectedAssetRoleIds={selectedAssetRoleIds}
                                />
                            </HeaderTabs.TabPane>
                            <HeaderTabs.TabPane
                                tab={t("global.super-assets")}
                                key={TABS.SUPER_ASSETS}
                            >
                                <SuperAssetsTable
                                    onSuperAssetClick={onSuperAssetClick}
                                    selectionContextMenu={selectionContextMenu}
                                    selectionChange={onSuperAssetsSelection}
                                    selectedSuperAssets={selectedSuperAssets}
                                    hasAnyRights={superAssetType =>
                                        rights?.some(
                                            superAssetMatch(superAssetType),
                                        )
                                    }
                                />
                            </HeaderTabs.TabPane>
                        </HeaderTabs>
                        <PermissionsDrawerContainer
                            title="Account Permissions"
                            description={getDescription(
                                tab === TABS.SUPER_ASSETS
                                    ? selectedSuperAssets
                                    : tab === TABS.ASSET_ROLES
                                    ? selectedAssetRoles
                                    : selectedAccounts,
                                tab,
                            )}
                            selectedAssets={
                                tab === TABS.SUPER_ASSETS
                                    ? selectedSuperAssets
                                    : tab === TABS.ASSET_ROLES
                                    ? selectedAssetRoles
                                    : {
                                          project: selectedAccounts,
                                      }
                            }
                            selectedEntities={userIds}
                            adminKind={ADMIN_KIND.GENERAL}
                            entityKind={ENTITY_KIND.USER}
                            assetKind={tab}
                            rights={rights}
                            workflowRights={workflowRights}
                            groupedPermissions={groupedPermissions}
                            // onCancel={onCloseAll}
                            onClose={onPermissionsDrawerClose}
                            onSave={saveRightsResource.mutate}
                            visible={Boolean(view === VIEWS.PERMISSIONS)}
                            businessRoles={businessRoles}
                        />
                    </>
                )}
            />
        </>
    );
};
