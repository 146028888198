import { useDic } from "@/components/Dic/useDic.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { assetType } from "@/constants/assets.constants";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";

const preferencesType = preferencesTypes.USER_ASSETS_ASSETS_GROUPS_TABLE;

export const useFetchAssetGroupsQuery = ({ accountId, userIds }) => {
    const { permissionsAssetsService } = useDic();

    return useQueryLoadable(
        async () =>
            permissionsAssetsService
                .fetchAssetGroups({
                    accountId,
                    userIds,
                })
                .then(getData),
        [accountId, permissionsAssetsService, userIds],
    );
};
export const AssetGroupsTable = React.memo(
    React.forwardRef((props, ref) => {
        const { accountId, userIds } = props;

        const workflowsQuery = useFetchAssetGroupsQuery({ accountId, userIds });
        const dataSource = workflowsQuery.loadable.valueMaybe() || [];

        useImperativeHandle(ref, () => ({
            reload: workflowsQuery.reload,
        }));

        return (
            <AssetsTable
                preferencesType={preferencesType}
                assetType={assetType.assetGroup}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                datasetSlicing="local"
                dataSource={dataSource}
                {...props}
            />
        );
    }),
);

AssetGroupsTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
