import { generateLocationsStack } from "@/components/Breadcrumb/Breadcrumbs";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    useCustomerPartition,
    useTemplateOrAdminTemplate,
} from "@/components/Marketplace/components/WarningStep/loadableResoures";
import { PageLayoutContext } from "@/components/PageLayout/PageLayoutContext";
import { getPageTitle as getPermissionsSubAssetsTitle } from "@/components/Permissions/SubAssets.admin.page";
import { getPageTitle as getPermissionUsersPageTitle } from "@/components/Permissions/UsersAccounts.admin.page";
import { getPageTitle as getPermissionsAccountPageTitle } from "@/components/Permissions/UsersAssets.admin.page";
import { useLocation } from "@/components/hooks/useLocation.hook";
import { useRouteAccount } from "@/mixpanel/hooks/useRouteAccount.hook";
import { useRouteAdminAsset } from "@/mixpanel/hooks/useRouteAdminAsset";
import { useRouteAdminUsersList } from "@/mixpanel/hooks/useRouteAdminUsersList.hook";
import { hasValue } from "@/modules/loadable";
import { useLoadableAlert } from "@/modules/loadable/useLoadableAlert.hook";
import { buildHashedUrl, useRouteParams } from "@/modules/router";
import { t } from "@/translations";
import { getUrlParams } from "@/utils/routeUtils";
import { last } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import { useRouteInstance } from "../../mixpanel/hooks/useRouteInstance.hook";

const initialState = {
    title: null,
    breadcrumbs: [],
};

export const BreadcrumbContext = React.createContext(initialState);

const BreadcrumbContextProvider = ({ children }) => {
    const { instanceLoadable } = useRouteInstance();
    const { adminUsersListLoadable } = useRouteAdminUsersList();
    const { adminAssetLoadable } = useRouteAdminAsset();
    const { accountLoadable } = useRouteAccount();

    const { title } = useContext(PageLayoutContext);
    const routeParams = useRouteParams();
    const { routerService } = useDic();

    const getPath = useCallback(
        (routeName, routeParams) => {
            try {
                return routerService.buildPath(routeName, routeParams);
            } catch (error) {
                console.warn(error);
                return "";
            }
        },
        [routerService],
    );

    const location = useLocation();
    const locations = useMemo(() => {
        if (location) {
            // requirement to hide first location from horizontal menu: https://pricefx.atlassian.net/browse/PFIM-2983
            const [_, ...locations] = generateLocationsStack(location);
            return locations;
        }
        return [];
    }, [location]);

    const maybePartition = useCustomerPartition(
        routeParams?.accountId,
        parseInt(routeParams?.partitionId, 10),
    ).loadable.valueMaybe();

    const templateOrAdminTemplateQuery = useTemplateOrAdminTemplate({
        packageName: routeParams?.packageName,
        accountId: routeParams?.accountId,
    });
    const templateOrAdminTemplateMaybe =
        templateOrAdminTemplateQuery.loadable.valueMaybe();
    useLoadableAlert({
        loadable: templateOrAdminTemplateQuery.loadable,
        hasError: e => ({
            type: "error",
            message: t("templates-management.error.unavailable"),
        }),
    });

    const mapPermissionsParamsToLabel = useCallback(
        params => {
            const paramName = last(params);
            switch (paramName) {
                case "accountId":
                    return hasValue(adminUsersListLoadable)
                        ? getPermissionsAccountPageTitle(accountLoadable)
                        : "";
                case "userIds":
                    return hasValue(adminUsersListLoadable)
                        ? getPermissionUsersPageTitle(adminUsersListLoadable)
                        : "";
                case "assetId":
                    return hasValue(adminUsersListLoadable)
                        ? getPermissionsSubAssetsTitle(adminAssetLoadable)
                        : "";

                default:
                    return ``;
            }
        },
        [adminUsersListLoadable, accountLoadable, adminAssetLoadable],
    );
    const mapCommonLabelByFirstParam = useCallback(
        paramName => {
            switch (paramName) {
                case "partitionId":
                    return maybePartition?.serialNumber ?? "";
                case "instanceId":
                    return `${
                        instanceLoadable.valueMaybe()?.instanceName ?? ""
                    }`;
                case "packageName":
                    return templateOrAdminTemplateMaybe?.label ?? "";
                default:
                    return ``;
            }
        },
        [
            maybePartition?.serialNumber,
            instanceLoadable,
            templateOrAdminTemplateMaybe?.label,
        ],
    );

    const getBreadcrumbLabel = useCallback(
        (location, isLastItem) => {
            const urlParams = getUrlParams(location.path);

            if (title && isLastItem) {
                return title;
            } else if (urlParams.length > 0) {
                if (
                    location.routeName.startsWith(
                        "administrationApp.permissions",
                    ) ||
                    location.routeName.startsWith("accountApp.permissions")
                ) {
                    return mapPermissionsParamsToLabel(urlParams);
                } else {
                    return mapCommonLabelByFirstParam(urlParams[0]);
                }
            }

            return t(`location.${location.routeName}`);
        },
        [mapCommonLabelByFirstParam, mapPermissionsParamsToLabel, title],
    );

    const breadcrumbs = useMemo(
        () =>
            locations.map((location, i) => {
                const isLastItem = locations.length - 1 === i;
                return {
                    label: getBreadcrumbLabel(location, isLastItem) || "",
                    href: buildHashedUrl(
                        getPath(location.routeName, routeParams),
                    ),
                };
            }),
        [locations, routeParams, getBreadcrumbLabel, getPath],
    );

    return (
        <BreadcrumbContext.Provider
            value={{
                breadcrumbs,
            }}
        >
            {children}
        </BreadcrumbContext.Provider>
    );
};

BreadcrumbContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BreadcrumbContextProvider;
