import { useDic } from "@/components/Dic/useDic.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { AssetsTable } from "@/components/Permissions/UsersAssets/AssetsTable.component";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";

const preferencesType =
    preferencesTypes.SUB_ASSETS_PARTITION_DATA_UPLOADS_TABLE;

export const SubAssetTable = React.memo(
    React.forwardRef((props, ref) => {
        const { accountId, userIds, assetType, parentAssetType, assetId } =
            props;
        const { permissionsAssetsService } = useDic();
        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort, filter) =>
                permissionsAssetsService.fetchSubAssets({
                    accountId,
                    assetId,
                    assetType,
                    parentAssetType,
                    page,
                    size,
                    sort,
                    filter,
                    userIds,
                }),
            [],
        );

        useImperativeHandle(ref, () => ({
            reload,
        }));

        return (
            <AssetsTable
                preferencesType={preferencesType}
                {...tableProps}
                assetType={assetType.dataUpload}
                defaultSort={{
                    fieldName: "name",
                    sortDir: "ascending",
                }}
                {...props}
            />
        );
    }),
);

SubAssetTable.propTypes = {
    accountId: PropTypes.number.isRequired,
    userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    assetId: PropTypes.number.isRequired,
    assetType: PropTypes.string.isRequired,
    parentAssetType: PropTypes.string.isRequired,
};
