import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import {
    useAdminUserRightsResource,
    useAdminUserWorkflowRightsResource,
    useSaveAdminRights,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { doAfterDrawerClose } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import { ENTITY_KIND } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { SubAssetsTable } from "@/components/Permissions/UsersAssets/SubAssetsTable.component";
import {
    joinUserIdsToUrlParam,
    useRouteUserIds,
} from "@/components/Permissions/utils";
import { useRouteAccount } from "@/mixpanel/hooks/useRouteAccount.hook";
import { logger } from "@/modules/logger";
import { useRouteParams } from "@/modules/router";
import React, { useCallback, useRef } from "react";

export const getPageTitle = adminAsset => {
    if (adminAsset.valueMaybe()?.serialNumber) {
        return `${adminAsset.valueMaybe()?.serialNumber ?? ""} partition`;
    } else if (adminAsset.valueMaybe()?.instanceName) {
        return `${adminAsset.valueMaybe()?.instanceName ?? ""} integration`;
    } else {
        return "";
    }
};

// export const getDescription = selectedItems =>
//     size(selectedItems) > 1
//         ? `Mass permissions settings for multiple selected users (${size(
//               selectedItems,
//           )})`
//         : "";

export const SubAssetsAdminPage = () => {
    const {
        locationRouterService,
        administrationAppLocations: { permissionsAdminUsersAssetsLocation },
    } = useDic();
    const { accountId, assetType, assetId } = useRouteParams();
    const { accountLoadable } = useRouteAccount();
    const userIds = useRouteUserIds();

    const navigateToAssetsList = useCallback(
        () =>
            locationRouterService.navigate(
                permissionsAdminUsersAssetsLocation,
                {
                    userIds: joinUserIdsToUrlParam(userIds),
                    accountId,
                },
            ),
        [
            locationRouterService,
            permissionsAdminUsersAssetsLocation,
            userIds,
            accountId,
        ],
    );
    useSetBackButtonAction(navigateToAssetsList);

    useFullWidthTableLayout();
    // useSetPageTitle(getPageTitle(accountLoadable));
    // useSetPageDescription(getDescription(userIds));

    const rightsResource = useAdminUserRightsResource({
        canFetch: !!userIds.length,
        userIds,
    });

    const workflowRightsResource = useAdminUserWorkflowRightsResource({
        canFetch: !!userIds.length,
        userIds,
    });
    const assetsComponentRef = useRef();

    const afterSave = useCallback(() => {
        assetsComponentRef.current.hidePermissionsDrawer();
        doAfterDrawerClose(() => {
            rightsResource.reload();
            assetsComponentRef.current.reload();
        });
    }, [rightsResource]);

    const saveRightsResource = useSaveAdminRights({
        afterSave,
    });

    logger.debug({
        logGroupKey: ["PERMISSIONS", "SubAssetsAdminPage"],
        color: "deepskyblue",
        data: {
            accountLoadable,
        },
    });

    return (
        <>
            <SubAssetsTable
                ref={assetsComponentRef}
                accountId={accountId}
                assetType={assetType}
                assetId={assetId}
                entityKind={ENTITY_KIND.USER}
                selectedEntities={userIds}
                rightsResource={rightsResource}
                workflowRightsResource={workflowRightsResource}
                saveRightsResource={saveRightsResource}
                // onClose={noop}
                // visible={visible}
                // displaySuperAssets={true} //displaySuperAssets}
            />
        </>
    );
};
