import { assetType } from "@/constants/assets.constants";
import { buildPageableUrl } from "@/utils/urlUtils";
import { axiosService } from "../axios";

const getBaseUrl = projectId => `/api/projects/${projectId}/asset-permissions/`;

const mapAssetTypeToUrl = at => {
    switch (at) {
        case assetType.partition:
            return "partitions";
        case assetType.integration:
            return "integrations";
        case assetType.dataUpload:
            return "data-uploads";
        case assetType.workflow:
            return "workflows";
        case assetType.scheduler:
            return "schedulers";
        case assetType.assetGroup:
            return "asset-groups";
        default:
            throw new Error(`Unknown asset type: ${at}`);
    }
};

export const permissionsAssetsService = {
    fetchProjects({ page, size, sort, filter, userIds = [] } = {}) {
        return axiosService.post(
            buildPageableUrl("/api/admin/projects", page, size, sort),
            {
                filter,
                userIds,
            },
        );
    },
    fetchPartitions({
        accountId,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                getBaseUrl(accountId) + "partitions",
                page,
                size,
                sort,
            ),
            {
                filter,
                userIds,
            },
        );
    },
    fetchSubAssets({
        accountId,
        assetId,
        assetType,
        parentAssetType,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                `${getBaseUrl(
                    accountId,
                )}${parentAssetType}s/${assetId}/${mapAssetTypeToUrl(
                    assetType,
                )}`,
                page,
                size,
                sort,
            ),
            {
                filter,
                userIds,
            },
        );
    },

    fetchIntegrations({
        accountId,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                getBaseUrl(accountId) + "integrations",
                page,
                size,
                sort,
            ),
            {
                filter,
                userIds,
            },
        );
    },

    fetchDataUploads({
        accountId,
        page,
        size,
        sort,
        filter,
        userIds = [],
    } = {}) {
        return axiosService.post(
            buildPageableUrl(
                getBaseUrl(accountId) + "data-uploads",
                page,
                size,
                sort,
            ),
            {
                filter,
                userIds,
            },
        );
    },
    fetchWorkflows({ accountId, userIds = [] } = {}) {
        return axiosService.post(getBaseUrl(accountId) + "workflows", {
            userIds,
        });
    },
    fetchSchedulers({ accountId, userIds = [] } = {}) {
        return axiosService.post(getBaseUrl(accountId) + "schedulers", {
            userIds,
        });
    },
    fetchAssetGroups({ accountId, userIds = [] } = {}) {
        return axiosService.post(getBaseUrl(accountId) + "asset-groups", {
            userIds,
        });
    },
};
