import { message } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useValueVisibility } from "@/components/hooks/useValueVisibility.hook";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import {
    isPricefxUsername,
    useAccountBusinessRolesResource,
    useAccountUserRightsResource,
    useAccountUserWorkflowRightsResource,
    useGroupedPermissionsResource,
    useSaveAccountRights,
} from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/loadables";
import { PermissionsDrawerContainer } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/PermissionsDrawer.container";
import { ADMIN_KIND } from "@/components/Permissions/AccountUserAdminSettings/RightsDrawer/PermissionsDrawer/sources.utils";
import { AssignUserToAtlassianGroupModal } from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettingsTable/atlassian/AssignUserToAtlassianGroupModal";
import { RemoveUserFromAtlassianGroupModal } from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettingsTable/atlassian/RemoveUserFromAtlassianGroupModal";
import { createColumns } from "@/components/Permissions/AccountUserAdminSettings/UserAdminSettingsTable/userAdminSettingsTable.columns";
import { joinUserIdsToUrlParam } from "@/components/Permissions/utils";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import {
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { ACCOUNT_USER_MANAGEMENT_PERMISSIONS } from "@/security/permission.utils";
import { t } from "@/translations";
import { noop } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";

const preferencesType = preferencesTypes.USERS_LIST_TABLE;
const INITIAL_VALUE = null;
export const UserAdminSettingsTableContainer = React.memo(
    ({ accountId, onUserDelete, filtersRef }) => {
        const {
            locationRouterService,
            accountAppLocations: { permissionsAccountUsersAssetsLocation },
            userService,
        } = useDic();

        // const [view, setView] = useState(VIEWS.DEFAULT);
        const [selectedUsers, setSelectedUsers] = useState([]);
        // const multipleIdsPermissions = useCallback(userIds => {
        //     setSelectedUsers(userIds);
        //     setView(VIEWS.SHOW_ASSETS_PERMISSIONS_PANEL);
        // }, []);
        // const onCancel = useCallback(() => {
        //     setView(VIEWS.DEFAULT);
        //     setSelectedUsers([]);
        // }, []);
        // const singleRecordPermissions = useCallback(
        //     user => multipleIdsPermissions([user.id]),
        //     [multipleIdsPermissions],
        // );

        const selectionContextMenu = useMemo(
            () => [
                {
                    label: t("general.mass-permissions"),
                    onClick: () =>
                        locationRouterService.navigate(
                            permissionsAccountUsersAssetsLocation,
                            {
                                userIds: joinUserIdsToUrlParam(selectedUsers),
                            },
                        ),
                },
            ],
            [
                locationRouterService,
                permissionsAccountUsersAssetsLocation,
                selectedUsers,
            ],
        );
        const rowSelection = useSyncRowSelection(
            selectedUsers,
            setSelectedUsers,
        );

        const columns = useMemo(() => createColumns(), []);
        const assignUserToAtlassianGroupModal = useDetailDrawerState();
        const removeUserFromAtlassianGroupModal = useDetailDrawerState();
        const isUserInvited = record => record.status === "INVITED";

        const resendActivation = useCallback(
            record =>
                userService
                    .resendActivation(accountId, record.id)
                    .then(() => {
                        message.success(
                            t(
                                "account-admin-users-list.resend-activation-email-ok",
                            ),
                        );
                    })
                    .catch(() => {
                        message.error(
                            t(
                                "account-admin-users-list.resend-activation-email-failed",
                            ),
                        );
                    }),
            [accountId, userService],
        );

        const accountPermissions = useValueVisibility(INITIAL_VALUE);

        const actionMenu = useCallback(
            record => (
                <ActionButton
                    wrapperProps={{ onClick: e => e.stopPropagation() }}
                    record={record}
                    items={[
                        {
                            title: t(
                                "account-admin-users-list.atlassian.permission-management",
                            ),
                            items: [
                                {
                                    title: t(
                                        "account-admin-users-list.atlassian.permission-management.account-permission-and-roles",
                                    ),
                                    onClick: record => {
                                        accountPermissions.show([record.id]);
                                    },
                                },
                                {
                                    title: t(
                                        "account-admin-users-list.atlassian.permission-management.asset-permissions",
                                    ),
                                    onClick: () =>
                                        locationRouterService.navigate(
                                            permissionsAccountUsersAssetsLocation,
                                            {
                                                userIds: `${record.id}`,
                                            },
                                        ),
                                },
                            ],
                        },
                        {
                            title: t(
                                "account-admin-users-list.atlassian.atlassian-management",
                            ),
                            items: [
                                {
                                    title: t(
                                        "account-admin-users-list.atlassian.assign-to-group",
                                    ),
                                    onClick:
                                        assignUserToAtlassianGroupModal.show,
                                },
                                record.atlassianAccountId && {
                                    title: t(
                                        "account-admin-users-list.atlassian.remove-from-group",
                                    ),
                                    onClick:
                                        removeUserFromAtlassianGroupModal.show,
                                    color: "red",
                                },
                            ],
                        },
                        {
                            title: t(
                                "account-admin-users-list.resend-activation-email",
                            ),
                            visible:
                                isUserInvited(record) &&
                                !isPricefxUsername(record.username),
                            permission: ACCOUNT_USER_MANAGEMENT_PERMISSIONS,
                            onClick: () => resendActivation(record),
                        },
                        {
                            title: t(
                                "account-admin-users-list.remove-user.title",
                            ),
                            confirmMessage: t(
                                "account-admin-users-list.remove-user.message",
                                {
                                    username: record.username,
                                },
                            ),
                            onConfirm: onUserDelete,
                            color: "red",
                        },
                    ]}
                />
            ),
            [
                assignUserToAtlassianGroupModal.show,
                locationRouterService,
                onUserDelete,
                permissionsAccountUsersAssetsLocation,
                removeUserFromAtlassianGroupModal.show,
                resendActivation,
                accountPermissions,
            ],
        );

        const [tableProps, { reload }] = useFetchPage(
            (page, size, sort, filter) =>
                userService.fetchUsersTable(
                    page,
                    size,
                    sort,
                    filter,
                    accountId,
                ),
            [accountId],
        );
        const afterSuccess = useCallback(() => {
            assignUserToAtlassianGroupModal.hide();
            removeUserFromAtlassianGroupModal.hide();
            reload();
        }, [
            assignUserToAtlassianGroupModal,
            removeUserFromAtlassianGroupModal,
            reload,
        ]);

        const groupedPermissionsResource = useGroupedPermissionsResource();
        const businessRolesResource = useAccountBusinessRolesResource({
            accountId,
        });
        // const accountAssetRolesResource = useAccountAssetRolesResource({
        //     accountId,
        //     canFetch: accountModalVisibility,
        // });

        const rightsResource = useAccountUserRightsResource({
            accountId,
            userIds: accountPermissions.value,
            canFetch: accountPermissions.visible,
        });
        const workflowRightsResource = useAccountUserWorkflowRightsResource({
            accountId,
            userIds: accountPermissions.value,
            canFetch: accountPermissions.visible,
        });

        const loadables = useComposeLoadablesMemoized([
            groupedPermissionsResource.loadable,
            businessRolesResource.loadable,
            // accountAssetRolesResource.loadable,
            rightsResource.loadable,
            workflowRightsResource.loadable,
        ]);

        const saveRightsResource = useSaveAccountRights({
            accountId,
            afterSave: noop,
        });

        const selectedAssets = useMemo(
            () => ({
                project: [accountId],
            }),
            [accountId],
        );

        return (
            <>
                <TableWithPreferencesManagement
                    actionMenu={actionMenu}
                    columns={columns}
                    fixed
                    restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
                    rowKey="id"
                    rowSelection={rowSelection}
                    selectionContextMenu={selectionContextMenu}
                    datasetSlicing="server"
                    preferencesType={preferencesType}
                    exportUrl={`/api/projects/${accountId}/users/export`}
                    // dataSource={users} // TODO? Currently fetched twice by parent component and this component
                    filtersRef={filtersRef}
                    {...tableProps}
                />
                <LoadableRenderer
                    loadable={loadables}
                    hasValue={([
                        groupedPermissions,
                        businessRoles,
                        rights,
                        workflowRights,
                    ]) => (
                        <PermissionsDrawerContainer
                            title="Account Permissions"
                            // description={
                            //     accountPermissionsModal.value > 1
                            //         ? `${size(
                            //               selectedAssets,
                            //           )} selected accounts`
                            //         : "William Doyle (william.doyle@pricefx.eu) TODO1"
                            // }
                            selectedAssets={selectedAssets}
                            selectedEntities={accountPermissions.value}
                            adminKind={ADMIN_KIND.PROJECT}
                            entityKind={"user"}
                            assetKind={"asset"}
                            rights={rights}
                            workflowRights={workflowRights}
                            groupedPermissions={groupedPermissions}
                            businessRoles={businessRoles}
                            onCancel={accountPermissions.hide}
                            onClose={accountPermissions.hide}
                            onSave={saveRightsResource.mutate}
                            visible={accountPermissions.visible}
                        />
                    )}
                />
                <AssignUserToAtlassianGroupModal
                    projectId={accountId}
                    record={assignUserToAtlassianGroupModal.record}
                    onClose={assignUserToAtlassianGroupModal.hide}
                    afterSuccess={afterSuccess}
                />
                <RemoveUserFromAtlassianGroupModal
                    projectId={accountId}
                    record={removeUserFromAtlassianGroupModal.record}
                    onClose={removeUserFromAtlassianGroupModal.hide}
                    afterSuccess={afterSuccess}
                />
            </>
        );
    },
);

UserAdminSettingsTableContainer.propTypes = {
    accountId: PropTypes.number.isRequired,
    filtersRef: PropTypes.object,
    onUserDelete: PropTypes.func.isRequired,
};
