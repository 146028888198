import { Alert, Button, Forms, Gap, Link } from "@/components/DesignSystem";
import { useDic } from "@/components/Dic/useDic.hook";
import { MAX_INT_INSTANCES } from "@/components/ImInstancesLimit/AccountInstancesLimitModal";
import { INSTANCE_TABS } from "@/components/Integrations/EditInstanceForm";
import {
    IM_ENV_TIER_DIVIDER_STRING,
    ProvisionedInstanceEnvironmentRow,
} from "@/components/Integrations/NewProvisionedInstanceForm/ProvisionedInstanceEnvironmentRow";
import { useIntegrationSupportedTagsQuery } from "@/components/Integrations/loadables";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { LoadableRenderer, useMapLoadableMemoized } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { SecurityContext } from "@/security/authorization";
import {
    ACCOUNT_INTEGRATION_ADMIN_FUNC,
    ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    hasPermission,
} from "@/security/permission.utils";
import { t } from "@/translations";
import {
    createSortVersions,
    isNewer,
    validateSemVer,
} from "@/utils/versionUtils";
import { DATE_FORMAT } from "@pricefx/unity-components/dist/es/constants";
import { Alert as AntdAlert } from "antd";
import { zipObject } from "lodash";
import { filter, first, get, map, pipe, update, values } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useInstanceEnvironmentsOptionsResource } from "./loadables";

const LAYOUT = [
    {},
    { width: "136px" },
    { width: "172px" },
    { width: "106px" },
    { width: "136px" },
    { width: "157px" },
    {},
    { width: "25px" },
];

const isOptDisabled = (env, envs) =>
    envs.some(({ envName, isExisting }) => env === envName && isExisting);
const to = map(
    update("expiration", ex => (ex ? moment(ex, DATE_FORMAT.DATE) : undefined)),
);

const limitReached = ({
    rows,
    initialValue,
    maxIntegrationInstances,
    existingInstances,
}) => {
    const totalInstances =
        existingInstances + (rows.length - initialValue.length);

    return totalInstances >= (maxIntegrationInstances ?? MAX_INT_INSTANCES);
};

// this wrapper won't be needed if the property of fieldProp will be returned in this MR:
// https://gitlab.pricefx.eu/engineering/pricefx-clients-js/-/merge_requests/18527
const ListWithInitialValues = ({ rowIds, initialValues, children }) => {
    const initialValuesByNameRef = useRef(null);
    if (
        !initialValuesByNameRef.current &&
        rowIds.length > 0 &&
        initialValues.length > 0
    ) {
        initialValuesByNameRef.current = zipObject(rowIds, initialValues);
    }
    return children({
        initialValuesByName: initialValuesByNameRef.current || {},
    });
};

export const ProvisionedInstanceEnvironments = React.memo(
    ({
        initialValue: initialValueProp = [],
        maxIntegrationInstances,
        existingInstances,
        newEnvVersion,
        name = "environments",
        disableEnvsEdit = false,
        instanceGroupName,
        isNewInstance = false,
        instancesRestartMutation,
        instancesDeleteMutation,
    }) => {
        const { accountId } = useAccountAppParams();
        const { locationRouterService, accountAppLocations } = useDic();
        const { settingsLocation } = accountAppLocations;

        const [modifiedExistingEnvs, setModifiedExistingEnvs] = useState({});

        const securityContext = useContext(SecurityContext);
        const canSetAdvancedOptions =
            ACCOUNT_INTEGRATION_ADMIN_FUNC(securityContext);
        const hasPermissionToSetExpiration = hasPermission(
            securityContext.permissions,
            ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
        );

        const { current: initialValue } = useRef(to(initialValueProp));
        const value = Forms.useFieldValue({ formId: Forms.useFormId(), name });
        const selectedEnvironments = useMemoByDeepEquality(
            pipe(values, map(get("envName")), filter(Boolean))(value),
        );
        const optionsQuery = useInstanceEnvironmentsOptionsResource({
            canSetAdvancedOptions,
        });
        const environmentOptionsLoadable = useMapLoadableMemoized(
            optionsQuery.loadable,
            useCallback(
                ([environments]) =>
                    environments
                        .map(env => env.name)
                        .filter(env => !selectedEnvironments.includes(env))
                        .map(env => ({
                            label: env,
                            value: env,
                            disabled: isOptDisabled(env, initialValue),
                        })),
                [initialValue, selectedEnvironments],
            ),
        );

        const initialValuesWithTiers = initialValue.map(env => ({
            ...env,
            ...(env.cpu && env.mem
                ? { tier: `${env.cpu}${IM_ENV_TIER_DIVIDER_STRING}${env.mem}` }
                : {}),
        }));

        const integrationTagsResource =
            useIntegrationSupportedTagsQuery(instanceGroupName);
        const integrationVersionsOptions = useMapLoadableMemoized(
            integrationTagsResource.loadable,
            map(({ name }) => ({
                label: name,
                value: name,
            })),
        );

        const integrationHighestVersion = useMapLoadableMemoized(
            integrationTagsResource.loadable,
            pipe(
                filter(ver => validateSemVer(ver.name)),
                createSortVersions({
                    getter: get("name"),
                }),
                first,
                get("name"),
            ),
        );

        const isEnvironmentUpgradeable = useCallback(
            ({ version, status }) =>
                status === "MATCHED" &&
                isNewer(integrationHighestVersion.valueMaybe(), version),
            [integrationHighestVersion],
        );

        const isAnyExistingEnvironmentUpgradable = initialValuesWithTiers.some(
            ({ version, status }) =>
                isEnvironmentUpgradeable({
                    version,
                    status,
                }),
        );

        const isAnyExistingEnvironmentModified =
            Object.values(modifiedExistingEnvs).some(Boolean);

        const onUpdate = useCallback(
            ({ instanceId }) => {
                locationRouterService.navigate(settingsLocation, {
                    accountId: accountId,
                    instanceId,
                    tab: INSTANCE_TABS.UPDATE,
                });
            },
            [accountId, locationRouterService, settingsLocation],
        );
        return (
            <LoadableRenderer
                loadable={optionsQuery.loadable}
                hasValue={([, tiers = [], storages = []] = []) => (
                    <Forms.FieldGrid layout={LAYOUT}>
                        <Forms.List
                            name={name}
                            initialValue={initialValuesWithTiers}
                        >
                            {({ rows, add, remove, rowIds }) => {
                                return (
                                    <ListWithInitialValues
                                        rowIds={rowIds}
                                        initialValues={initialValuesWithTiers}
                                    >
                                        {({ initialValuesByName }) => (
                                            <>
                                                {isAnyExistingEnvironmentUpgradable && (
                                                    <>
                                                        <Alert
                                                            description="An update to a newer version is available for some environments. Click the arrow icon next to the version to open the Update Assistant."
                                                            closable
                                                        />
                                                        <Gap size="small" />
                                                    </>
                                                )}
                                                {isAnyExistingEnvironmentModified && (
                                                    <>
                                                        <Alert
                                                            description="Select one of the preset combinations."
                                                            message="Custom combinations of CPU and Memory sizes are no longer supported."
                                                            type="warning"
                                                        />
                                                        <Gap size="small" />
                                                    </>
                                                )}
                                                {rows.map(
                                                    ({ id, fieldProps }) => (
                                                        <ProvisionedInstanceEnvironmentRow
                                                            canSetAdvancedOptions={
                                                                canSetAdvancedOptions
                                                            }
                                                            disableEnvsEdit={
                                                                disableEnvsEdit
                                                            }
                                                            environmentOptionsLoadable={
                                                                environmentOptionsLoadable
                                                            }
                                                            fieldProps={
                                                                fieldProps
                                                            }
                                                            hasPermissionToSetExpiration={
                                                                hasPermissionToSetExpiration
                                                            }
                                                            id={id}
                                                            initialValuesByName={
                                                                initialValuesByName
                                                            }
                                                            instanceGroupName={
                                                                instanceGroupName
                                                            }
                                                            instancesDeleteMutation={
                                                                instancesDeleteMutation
                                                            }
                                                            instancesRestartMutation={
                                                                instancesRestartMutation
                                                            }
                                                            integrationVersionsOptions={
                                                                integrationVersionsOptions
                                                            }
                                                            isEnvironmentUpgradeable={
                                                                isEnvironmentUpgradeable
                                                            }
                                                            isNewInstance={
                                                                isNewInstance
                                                            }
                                                            key={id}
                                                            newEnvVersion={
                                                                newEnvVersion
                                                            }
                                                            onUpdate={onUpdate}
                                                            remove={remove}
                                                            rows={rows}
                                                            setModifiedExistingEnvs={
                                                                setModifiedExistingEnvs
                                                            }
                                                            storages={storages}
                                                            tiers={tiers}
                                                        />
                                                    ),
                                                )}
                                                {limitReached({
                                                    rows,
                                                    initialValue,
                                                    maxIntegrationInstances,
                                                    existingInstances,
                                                }) && (
                                                    <>
                                                        <Gap size="small" />
                                                        <AntdAlert
                                                            showIcon
                                                            type="warning"
                                                            message={t(
                                                                "increase-ims-limit.form.hint",
                                                                {
                                                                    link: (
                                                                        <Link
                                                                            targetBlank
                                                                            href="https://pricefx.atlassian.net/wiki/display/USERKB/IT+Helpdesk"
                                                                        >
                                                                            {t(
                                                                                "increase-ims-limit.form.hint-link-text",
                                                                            )}
                                                                        </Link>
                                                                    ),
                                                                },
                                                            )}
                                                        />
                                                    </>
                                                )}
                                                <Gap size="small" />
                                                <Button
                                                    size="small"
                                                    label={t(
                                                        "instance-form.button.add",
                                                    )}
                                                    onClick={() => add()}
                                                    disabled={
                                                        !environmentOptionsLoadable.valueMaybe()
                                                            ?.length ||
                                                        limitReached({
                                                            rows,
                                                            initialValue,
                                                            maxIntegrationInstances,
                                                            existingInstances,
                                                        })
                                                    }
                                                />
                                            </>
                                        )}
                                    </ListWithInitialValues>
                                );
                            }}
                        </Forms.List>
                    </Forms.FieldGrid>
                )}
            />
        );
    },
);

ProvisionedInstanceEnvironments.propTypes = {
    initialValue: PropTypes.array,
    maxIntegrationInstances: PropTypes.number,
    existingInstances: PropTypes.number,
    newEnvVersion: PropTypes.string,
    name: PropTypes.string,
    disableEnvsEdit: PropTypes.bool,
    instanceGroupName: PropTypes.string,
    isNewInstance: PropTypes.bool,
    instancesRestartMutation: PropTypes.func.isRequired,
    instancesDeleteMutation: PropTypes.func.isRequired,
};
